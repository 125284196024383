
import * as strapiRuntime$bbOi4tzSy3Jz2svhiyxWmKMmXnWRwBIQ8k6_nEhXV_45k from '/app/node_modules/.pnpm/@nuxt+image@1.10.0_@capacitor+preferences@6.0.3_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/strapi'
import * as belongRuntime$SqKuZtrwoACNXnqD9XXrClJfRn88rzdf1fC3PPAZI3Q from '/app/apps/website/app/providers/s3-belong.ts'
import * as ipxRuntime$E3JqeGlGScyd_45cuLK3GE_45z4nzq9zh_r_tlhccu2E8Xc from '/app/node_modules/.pnpm/@nuxt+image@1.10.0_@capacitor+preferences@6.0.3_db0@0.3.1_idb-keyval@6.2.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['strapi']: { provider: strapiRuntime$bbOi4tzSy3Jz2svhiyxWmKMmXnWRwBIQ8k6_nEhXV_45k, defaults: undefined },
  ['belong']: { provider: belongRuntime$SqKuZtrwoACNXnqD9XXrClJfRn88rzdf1fC3PPAZI3Q, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$E3JqeGlGScyd_45cuLK3GE_45z4nzq9zh_r_tlhccu2E8Xc, defaults: {} }
}
        